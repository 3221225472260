import Api from "./Api";

export const AddBudget = async (payload) => Api().post(`budget`, payload);

export const GetBudget = async () => Api().get(`budget`);

export const GetBudgetOverView = async (id, payload) =>
  Api().post(`budget/overview/${id}`, payload);

export const GetBudgetTerritory = async (id) =>
  Api().get(`budget/territory/${id}`);

export const GetBudgetCategory = async (id) =>
  Api().get(`budget/category/${id}`);

export const SaveBudgetSingle = async (payload) =>
  Api().post(`budget/single/`, payload);

// not used
// export const GetBudgetMovie = async () => Api().get(`budget/budgetmovie`);

// export const GetBudgetSubCategory = async (id) =>
//   Api().get(`budget/sub_category/${id}`);

// export const DeleteCat = async (payload) =>
//   Api().post(`budget/delete/category`, payload);

// export const DeleteSubCat = async (payload) =>
//   Api().post(`budget/delete/sub_category`, payload);
