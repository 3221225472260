<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" sm="12">
            <!-- <div class="d-flex align-items-center justify-content-end"> -->
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1 mb-1 mb-md-0"
              placeholder="Search..."
              @click="onFiltersChange"
            />
          </b-col>
          <b-col cols="12" md="2" sm="12">
            <b-button
              class="mb-1"
              v-if="$can('create', 'budget_expense')"
              block
              variant="primary"
              @click="
                () => {
                  $router.push('/budget/add');
                }
              "
            >
              <span class="text-nowrap">Add Budget</span>
            </b-button>
            <!-- </div> -->
          </b-col>
        </b-row>
      </div>

      <b-table
        bordered
        class="position-relative"
        :items="budget_list"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Actual -->
        <template #cell(budgeted)="data">
          {{ data.item.budgeted ? data.item.budgeted : 0 }}
        </template>
        <!-- Column: Actual -->
        <template #cell(actual)="data">
          {{ data.item.actual ? data.item.actual : 0 }}
        </template>

        <!-- Column: Balance -->
        <template #cell(balance)="data">
          {{ data.item.budgeted ? data.item.budgeted : 0 }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon
            size="18"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.right.v-primary
            variant="outline-primary"
            title="Overview"
            @click="
              () => {
                $router.push('/budget/overview/' + data.item.budget_main_id);
              }
            "
            icon="EditIcon"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing 1 to 10 of 15 entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <!-- <b-pagination
              value="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.rowsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination> -->
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  VBPopover,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import { GetBudget } from "@/apiServices/BudgetServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
  },

  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      pagination: {
        currentPage: 0,
        totalRows: 1,
        rowsPerPage: 10,
        perPageOptions: [10, 25, 50, 100],
      },
      tableColumns: [
        { label: "Title", key: "title", sortable: true },
        { label: "Budgeted", key: "budgeted", sortable: true },
        { label: "Actual", key: "actual", sortable: true },
        { label: "Balance", key: "balance", sortable: true },
        { key: "actions" },
      ],
      filters: { country: null, status: null },
      budget_list: [],
      search: "",
    };
  },

  methods: {
    onFiltersChange() {
      // this.pagination.currentPage = 1;
      this.onChange();
    },
    onChange() {
      this.$nextTick(() => {
        this.getBudgetList();
      });
    },
    async getBudgetList() {
      try {
        const response = await GetBudget();
        if (response.status == 200) {
          this.budget_list = response.data.data;
        }
      } catch (err) {
        console.log("Error => ", err.message);
      }
    },
  },

  watch: {
    search() {
      this.getBudgetList();
    },
  },

  beforeMount() {
    this.getBudgetList();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>
